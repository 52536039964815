<script lang="ts">
export {
  sizes,
  variants,
} from '~theme/components/atoms/styles/ButtonFlatStyle.vue';

export type {
  Size,
  Variant,
} from '~theme/components/atoms/styles/ButtonFlatStyle.vue';
</script>

<script lang="ts" setup>
import { type ButtonHTMLAttributes } from 'vue';
import IconSpinner from '~theme/components/atoms/icons/IconSpinner.vue';
import ButtonFlatStyle, {
  type Size,
  type Variant,
} from '~theme/components/atoms/styles/ButtonFlatStyle.vue';

const props = withDefaults(
  defineProps<{
    size?: Size;
    variant?: Variant;
    disabled?: boolean;
    loading?: boolean;
    type?: ButtonHTMLAttributes['type'];
  }>(),
  {
    size: 'medium',
    variant: 'secondary',
    disabled: false,
    loading: false,
    type: 'button' as const,
  }
);

defineOptions({
  inheritAttrs: false,
});

const shouldDisableButton = computed(() => props.disabled || props.loading);
</script>

<template>
  <ButtonFlatStyle
    :disabled="shouldDisableButton"
    :size="size"
    :variant="variant"
  >
    <button
      :type="type"
      :disabled="shouldDisableButton"
      v-bind="$attrs"
      :class="[
        $style.button,
        {
          [$style['button--loading']]: loading,
        },
      ]"
    >
      <slot v-if="!loading" />
      <div
        v-else
        :class="[
          $style.button__spinner,
          $style[`button__spinner--${size}`],
          $style.spinner,
        ]"
      >
        <IconSpinner
          :class="[$style.spinner__icon, $style[`spinner__icon--${size}`]]"
        />
      </div>
    </button>
  </ButtonFlatStyle>
</template>

<style lang="scss" module>
.button {
  &--loading {
    line-height: 1;
  }

  &__spinner {
    &--small {
      height: 20px;
    }

    &--medium {
      height: 24px;
    }

    &--large {
      height: 28px;
    }
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    font-size: 12px;

    &--small {
      font-size: 12px;
    }

    &--medium {
      font-size: 14px;
    }

    &--large {
      font-size: 16px;
    }
  }
}
</style>
